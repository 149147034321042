import { FaRegHandshake, FaRegClipboard, FaHeadset } from 'react-icons/fa';

export const services = [
    {
        icon: <FaRegHandshake className='icon'/>,
        title: 'Consultoria',
        text: 'Atendimento especializado na área de óleos isolantes para equipamentos elétricos, na gestão de dados e informações técnicas na área de química e de materiais no setor, principalmente em manutenção e desempenho de equipamentos como transformadores, reatores e equipamentos correlatos. Apoio remoto e local conforme necessidade e nível crítico do problema a ser avaliado. Suporte especializado e dedicado para a demanda específica de cada cliente.'
    },
    {
        icon: <FaRegClipboard className='icon'/>,
        title: 'Gestão de Projetos',
        text: 'Apoio completo na elaboração, avaliação, implantação e gestão de projetos de pesquisa e desenvolvimento, de implantação laboratorial, de implantação de normas técnicas e de análise crítica de processo nas empresas ligadas ao setor elétrico. Modelagem PMI, com treinamentos específicos e dedicados. Cursos sob demanda do cliente on-site e on-line.'
    },
    {
        icon: <FaHeadset className='icon'/>,
        title: 'Suporte Técnico',
        text: 'Atendimento remoto e local para análise estatística e de tendências de desempenho, baseadas em dados físico-químicos e de gases dissolvidos, durante a operação de equipamentos do cliente em campo, na geração, transmissão e distribuição de energia. Apoio completo na pré-organização de laboratórios para solicitação da NBR 13882:2021 e na NBR 17025:2017, e para todas as normas de ensaio de óleos isolantes.'


    },
]
