import './MoreAbout.scss';
import Header from '../../layout/Header/Header';
import Footer from '../../layout/Footer/Footer';

function MoreAbout() {
    return (
        <>
            <Header />

            <h1>Mais sobre a Oilflex</h1>

            <div className='text-holder'>
                <h2 className='micro-titles'>A empresa atua em:	</h2>
                <ul>
                    <li>Consultoria em Gestão Empresarial de Laboratórios;</li>
                    <li>Tratamento de Dados Físico-Químicos e de Gases Dissolvidos;</li>
                    <li>Treinamento Profissional;</li>
                    <li>Educação Tecnológica sob Demanda.</li>
                </ul>

                <h2 className="micro-titles">Na área de consultoria atuamos com:</h2>
                <ul>
                    <li>Apoio na implementação de ensaios de laboratórios para realização de análises físico-químicas e especiais em óleos isolantes, além de análises de gases dissolvidos em óleos isolantes;</li>
                    <li>Suporte na interpretação e emissão de diagnóstico sobre as condições dos óleos e/ou equipamentos, em função dos resultados obtidos nas análises, com recomendação de ações de manutenção preditiva ou corretiva;</li>
                    <li>Elaboração de sistema estatístico para acompanhamento dos resultados de análises e de dados históricos, fornecendo ao cliente a situação das condições do óleo e/ou equipamento, com as tendências de comportamento futuro dos equipamentos.</li>
                </ul>

                <h2 className="micro-titles">Na área de serviços fornecemos as seguintes soluções:</h2>
                <ul>
                    <li>Regeneração de óleo mineral isolante em planta fixa;</li>
                    <li>Regeneração de óleo mineral isolante com transformadores energizados, na instalação do cliente;</li>
                    <li>Secagem de óleo mineral isolante e desidratação de parte ativa de transformadores, com utilização de filtros desidratadores;</li>
                    <li>Descontaminação de óleos minerais isolantes que apresentem enxofre corrosivo, pela retirada de dibenzildissulfeto (DBDS), com retorno ao uso;</li>
                    <li>Descontaminação de óleos minerais isolantes que possuam bifenilas policloradas – PCB (ascarel), podendo o óleo retornar ao uso, baseando-se na legislação vigente;</li>
                    <li>Desenvolvimento de processos de regeneração de óleos minerais isolantes, visando atender aos projetos de P&D, ou necessidades especificas do cliente;</li>
                    <li>Desenvolvimento e implantação de ensaios químicos especiais;</li>
                    <li>Desenvolvimento de novas metodologias de ensaios físico-químicos;</li>
                    <li>Elaboração e aplicação de cursos nas áreas de materiais e química;</li>
                    <li>Apoio técnico na criação de ambientes e na execução de serviços, projetos e programas de pesquisa;</li>
                    <li>Avaliações periódicas da qualidade e confiabilidade dos resultados de análises físico-químicas e de gases dissolvidos realizadas no óleo isolante;</li>
                    <li>Manutenção de laboratórios físico-químicos atualizados em relação a normas e procedimentos vigentes no Brasil;</li>
                    <li>Treinamento técnico, visando aprimorar os conhecimentos, para emissão de laudos complexos de equipamentos em uso, principalmente quanto a resultados de análises de gases dissolvidos em óleo isolante;</li>
                    <li>Suporte para participação efetiva nas comparações laboratoriais formais;</li>
                    <li>Especificação técnica de equipamentos para a realização e implantação de novos ensaios;</li>
                    <li>Preparação de Procedimentos de Ensaio e Análise (PEA), para ensaios já implantados e novos;</li>
                    <li>Implantação de laboratório para análises físico-químicas e de gases dissolvidos no óleo isolante;</li>
                    <li>Completo suporte para implantação da NBR 13882:2021 em laboratório de ensaios físico-químicos;</li>
                    <li>Completo suporte prévio para implantação da NBR 17025:2017 em laboratório de ensaios físico-químicos e de gases dissolvidos no óleo isolante, para o setor elétrico.</li>
                </ul>

                <h2 className='micro-titles'>Na área de Elaboração e Execução de Projetos fornecemos os seguintes suportes:</h2>
                <ul>
                    <li>Suporte para elaboração e execução de projetos de P&D para atendimento aos requisitos do PEQUI ANEEL;</li>
                    <li>Projetos para desenvolvimento de sistemas de tratamento e regeneração de líquidos isolantes.</li>
                </ul>
            </div>

            <Footer />
        </>
    )
}

export default MoreAbout;
