import ufpr from '../assets/partners/UFPRLogo.png';
import chronion from '../assets/partners/ChronionLogo.png';
import mgm from '../assets/partners/mgm-red.png';
import gj from '../assets/partners/gj2w-red.png';
import lopes from '../assets/partners/lopes-red.png';

import playbook from '../assets/partners/playbook-red.png';
import power from '../assets/partners/power-red.png';
import avero from '../assets/partners/vero-red.png';

export const upperPartners = [
    {
        alt: 'UFPR',
        image: ufpr,
        link: 'https://ufpr.br/'
    },
    {
        alt: 'chronion',
        image: chronion,
        link: 'https://chronion.com.br/'
    },
    {
        alt: 'mgm',
        image: mgm,
        link: 'https://www.mgmdiag.com.br/'
    },
    {
        alt: 'gj2w',
        image: gj,
        link: 'https://gj2wtech.com/'
    },
    {
        alt: 'lopesConsultoria',
        image: lopes,
        link: 'https://lopesconsultoria.online/'
    }
]

export const lowerPartners = [
    {
        alt: 'playbook',
        image: playbook,
        link: 'https://playbooklab.com.br/'
    },
    {
        alt: 'powermattech',
        image: power,
        link: 'https://powermattech.com/'
    },
    {
        alt: 'averodomino',
        image: avero,
        link: 'https://averodomino.com.br/'
    }
]
