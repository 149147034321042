import { FaLinkedin } from 'react-icons/fa';
import './Footer.scss';

function Footer() {
    return (
        <footer className='footer'>
            <div className='infos'>
                <h2>Endereço</h2>
                <p>Rua Plácido Prevedello 77 – Curitiba <br />  PR – CEP: 82200-420</p>

                <h2>Contatos</h2>
                <p>Email: oilflex@oilflex.com.br</p>
                <p>Telefone: (41) 99113-3388 - (41) 99191-0050</p>
            </div>
            <div className='social-media'>
                <h2>Redes Sociais</h2>
                <a href="https://www.linkedin.com/company/oilflex-consultoria-projetos/" target='_blank' rel='noreferrer'>
                    <FaLinkedin className='icon'/>
                </a>
            </div>
            <p className='rights'>Copyright ©2025 Oilflex - Desenvolvido por Gabriel Castro - gabrielcastro.developer@gmail.com</p>
        </footer>
    )
}

export default Footer;
