import anaMaria from '../assets/colabs/anaMaria.jpeg';
import breno from '../assets/colabs/brenoPinheiro.jpg';
import carlos from '../assets/colabs/carlosDuarte.jpeg';
import gilson from '../assets/colabs/gilsonPaulillo.jpeg';
import joao from '../assets/colabs/joaoRemonatto.jpeg';
import jordana from '../assets/colabs/jordanaAlves.jpeg';
import thiago from '../assets/colabs/thiagoLippo.jpeg';

const colabs = [
    {
        name: 'Ana Maria Guimarães',
        img: anaMaria,
        linkedIn: 'https://www.linkedin.com/in/ana-maria-guimaraes-a235887/'
    },
    {
        name: 'Breno Pinheiro',
        img: breno,
        linkedIn: 'https://www.linkedin.com/in/brenocarneiropinheiro/'
    },
    {
        name: 'Carlos Duarte',
        img: carlos,
        linkedIn: 'https://www.linkedin.com/in/carlos-duarte-costa-54ab5830/'
    },
    {
        name: 'Gilson Paulillo',
        img: gilson,
        linkedIn: 'https://www.linkedin.com/in/gilsonpaulillo/'
    },
    {
        name: 'João Remonatto',
        img: joao,
        linkedIn: 'https://www.linkedin.com/in/joao-vitor-remonatto-144bb8182/'
    },
    {
        name: 'Joardana Alvez',
        img: jordana,
        linkedIn: 'https://www.linkedin.com/in/jordana-alves-9aa194126/'
    },
    {
        name: 'Thiago Lippo',
        img: thiago,
        linkedIn: 'https://www.linkedin.com/in/thiagolippo/'
    }
]

export default colabs;
