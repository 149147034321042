import './ColabCard.scss';
import { FaLinkedinIn } from "react-icons/fa";

function ColabCard({ img, name, link }) {
  return (
    <div className='colab-card'>
        <img src={img} alt={`Foto de ${name}`} />
        <h4>{name}</h4>
        <a href={link} target='_blank' rel='noreferrer'>
            <FaLinkedinIn className='icon' />
            LinkedIn
        </a>
    </div>
  )
}

export default ColabCard;
